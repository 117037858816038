import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { bus } from "@/main";
import vToolTip from "v-tooltip";
import {mapState} from "vuex";

const DEFAULT_FORM_STATE = {
  discount_type: 'fixed',
  discount_amount: 0,
  type: 'quotation',
  gst_no: null,
  gst: 0
};

const DEFAULT_CALC = {
  fnb: 0,
  decors: 0,
  other_charges: 0,
  rooms: 0
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Lead Name",
    sortable: false,
    value: 'lead',
  },
  {
    text: "Event Type",
    sortable: false,
    value: 'event',
  },
  {
    text: "Total",
    sortable: false,
    value: 'total',
  },
  {
    text: "Created At",
    sortable: false,
    value: 'updated_at',
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
    width: "12%",
    align: "center"
  },
];

export default {
  data() {
    return {
      lead: {},
      event: {},
      operationTitle: "Quotations",
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      errors: [],
      edit: false,
      dropdowns: {
        leads: [],
        events: [],
        discountType: [
          { value: 'percentage', text: 'Percentage', },
          { value: 'fixed', text: 'Fixed' }],
        rooms: [],
      },
      listUrl: null,
      columns: COLUMN_DEFINATION(this),
      EventId: null,
      bookData: [],
      quotationEventDetail: [],
      mainTotal: 0,
      preference_calc: { ...DEFAULT_CALC },
      preference_gst_calc: { ...DEFAULT_CALC },
      displayTotal: 0,
      totalGst: 0,
      preferenceItems: {
        fnb: [],
        decors: [],
        other_charges: [],
        rooms: []
      }
    };
  },
  computed: {
    ...mapState([
      'global',
    ]),
    is_invoice() {

      return true
    },
    type() {
      return this.is_invoice ? 'invoice' : 'quotation'
    },
    discount() {

    }
  },
  created() {

    this.listUrl = `/quotations/${this.type}`

    this.operationTitle = this.type === "invoice" ? "Invoice":"Quotations";
  },
  methods: {
    async handleSubmit() {
      if ( this.formFields.discount_type === undefined ) {
        this.formFields.discount_type = null;
      }
      this.formFields.type = this.is_invoice ? "invoice" : "quotation"
      this.errors = [];
      try {
        const response = await request({
          url: this.formFields.id ? '/quotations/update' : '/quotations/create',
          method: 'post',
          data: { ...this.formFields, ...this.preferenceItems }
        });

        const { data } = response.data;

        if ( data.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }

        await this.handleBack();

      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.errors = error.data.errors;
          this.$refs['fnb'].displayErrors(JSON.parse(error.request.responseText).errors);
          this.$refs['other_charges'].displayErrors(JSON.parse(error.request.responseText).errors);
          this.$refs['decors'].displayErrors(JSON.parse(error.request.responseText).errors);
          this.$refs['rooms'].displayErrors(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
    async handleUpdate(changed_item, changed_status) {
      if ( changed_item !== 'refresh' ) {
        this.$set(this.preferenceItems, changed_item, changed_status);
        const total = this.totalAmountPreferences(changed_status);
        this.$set(this.preference_calc, changed_item, total.total);
        this.$set(this.preference_gst_calc, changed_item, total.gstAmount);
        this.getTotal();
      } else {
        if ( changed_status ) {
          await this.handleEditOperation(this.formFields.id);
          this.getTotal();
        }
      }
    },
    async getRooms() {

      try {
        const response = await request({
          url: `/dropdowns/rooms`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.rooms = data;
      } catch (e) {

      }
    },
    dateDisabled(ymd, date) {
      var myCurrentDate = new Date(date);
      var myPastDate = new Date(myCurrentDate);
      myPastDate.setDate(myPastDate.getDate() + 1);

      return ( myPastDate < new Date(this.filters.from_date) );
    },
    handleOperationCloseCheck() {
      if ( this.handler ) {
        if ( confirm('changes that you made may not be saved?') ) {
          this.handleQuotationCustomerDetailSubmit();
        }
        return false;
      } else {
        this.handleOperationClose();
      }
    },
    afterCloseOperation() {
      this.formFields = { ...DEFAULT_FORM_STATE };
      this.errors = [];
    },
    async handleBack() {
      this.$router.push(`/${this.type}`);
      this.afterCloseOperation();
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/quotations/detail/${ id }/${ this.formFields.type }`,
        });
        const { data } = response.data;

        this.mainTotal = data.total.total_without_discount;
        this.displayTotal = data.total.total;

        this.formFields = {
          id: data.id,
          discount_type: data.discount_type,
          discount_amount: data.discount_amount,
          gst_no: data.gst_no,
          gst: data.gst,
        };

        if ( data.preferences ) {

          this.preferenceItems = _.groupBy(data.preferences, 'object_type');

          let fnb = _.filter(data.preferences, (i) => i.object_type == 'fnb');
          let decor = _.filter(data.preferences, (i) => i.object_type == 'decors');
          let other_charges = _.filter(data.preferences, (i) => i.object_type == 'other_charges');
          let rooms = _.filter(data.preferences, (i) => i.object_type == 'rooms');

          // this.preference_calc
          fnb = fnb.length ? this.totalAmountPreferences(fnb) : null;
          decor = decor.length ? this.totalAmountPreferences(decor) : null;
          rooms = rooms.length ? this.totalAmountPreferences(rooms) : null;
          other_charges = other_charges.length ? this.totalAmountPreferences(other_charges) : null;

          // this.preference_calc
          this.preference_calc.fnb = fnb ? fnb.total : 0;
          this.preference_calc.decors = decor ? (decor).total : 0;
          this.preference_calc.rooms = rooms ? (rooms).total : 0;
          this.preference_calc.other_charges = other_charges ? (other_charges).total : 0;

          // this.preference_calc
          this.preference_gst_calc.fnb = fnb ? fnb.gstAmount : 0;
          this.preference_gst_calc.decors = decor ? (decor).gstAmount : 0;
          this.preference_gst_calc.rooms = rooms ? (rooms).gstAmount : 0;
          this.preference_gst_calc.other_charges = other_charges ? (other_charges).gstAmount : 0;

          this.getTotal();
        }

        this.lead = data.lead ? data.lead.label : "";
        this.event = data.event ? data.event.label : "";

      } catch (e) {
        console.error(e);
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            method: 'post',
            url: '/quotations/delete',
            data: {
              id: id,
            },
          });
          this.$global.itemDeleted();
          this.loadList(this.listQueryParams);
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async downloadPdf(id, action = 'download') {
      try {
        const response = await request({
          method: 'get',
          url: `quotations/${ action }/pdf/${ id }`,
        });

        const { data } = response.data;

        if ( data ) {
          window.open(data.download_url, '_blank').focus();
        }

      } catch (e) {
      }
    },
    handleOperationClose() {
      this.formFields = { ...DEFAULT_FORM_STATE };
      this.$router.push({ name: "quotationList" });
    },
    getTotal() {
      const totalPreference = parseFloat(this.preference_calc.fnb) + parseFloat(this.preference_calc.decors) + parseFloat(this.preference_calc.rooms) + parseFloat(this.preference_calc.other_charges);
      const totalGST = parseFloat(this.preference_gst_calc.fnb) + parseFloat(this.preference_gst_calc.decors) + parseFloat(this.preference_gst_calc.rooms) + parseFloat(this.preference_gst_calc.other_charges);

      console.log({totalPreference, totalGST: this.preference_gst_calc})
      if ( !totalPreference ) {
        this.displayTotal = 0;
        this.mainTotal = 0;
      }

      this.totalGst = totalGST;
      if ( totalPreference < this.formFields.discount_amount ) {
        this.formFields.discount_amount = 0;
      } else {
        this.displayTotal = totalPreference - this.formFields.discount_amount;
        this.mainTotal = totalPreference;
      }
    },
    handleTotal() {


      if ( this.formFields.discount_amount > 0 ) {
        if ( parseFloat(this.mainTotal) - parseFloat(this.formFields.discount_amount) >= 0 ) {
          this.displayTotal = parseFloat(this.mainTotal) - parseFloat(this.formFields.discount_amount);
          if (this.formFields.gst) {
            this.displayTotal += parseFloat(this.formFields.gst);
          }
        } else {
          this.formFields.discount_amount = 0;
        }
      }
    },
  },
};
