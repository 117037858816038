const handleError = {
    data() {
        return {
            errors: [],
        }
    },
    methods: {
        hasError(field) {
            return this.errors?.[field]?.[0]?.length > 0
        },
        getError(field) {
            return this.errors?.[field]?.[0]
        },
        resetError(field) {
            delete this.errors[field]
        },
    },
}

export default handleError
